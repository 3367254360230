require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

//import SlimSelect from 'slim-select'
//import html2canvas from 'html2canvas';
//window.SlimSelect = SlimSelect; // for error is not defined

//import 'slim-select/dist/slimselect.min.js';
//window.SlimSelect = SlimSelect; // for error is not defined
//import 'jquery-ui/ui/widgets/accordion.js';

window.SlimSelect   = require('slim-select');

require('./custom');