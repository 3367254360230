$( document ).ready(function() {

    $.ajaxSetup({
        headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    
    /*
    $('#doctors-table').DataTable({
        processing: true,
        serverSide: true,
        ajax: "{{ url('doctor-list-data') }}",
        columns: [
            { data: 'id', name: 'id' },
            { data: 'name', name: 'name' },
            { data: 'email', name: 'email' },
            { data: 'created_at', name: 'created_at' },
        ],
        order: [[0, 'desc']]
    });
    */

   $('form').submit(function (event) {
        
        $(this).find(':submit').html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;<span>Procesando</span>');
        $(this).find(':submit').prop('disabled', true); 
        
    });

    $("#btn-search").click(function(){
        createFilterUrl();        
    });

    $('.search-box').on('keypress',function(e) {
        if(e.which == 13) {
            createFilterUrl();
        }
    });

    $('.global-search-box').on('keypress',function(e) {
        if(e.which == 13) {
            window.location.href = config.routes.url_global_search+'?search='+$(this).val();
        }
    });

    $('.order-box').on('change', function () {
        createFilterUrl();
    });

    $('.type-box').on('change', function () {
        //createFilterUrl();
    });

    $('.status-box').on('change', function () {
        //createFilterUrl();
    });

    function createFilterUrl(){
        let url = '';

        if($('.search-box').val()){
            url = '?search='+$('.search-box').val();
        }

        if($('.order-box').val()){
            url = url?url+'&order='+$('.order-box').val():'?order='+$('.order-box').val();
        }

        if($('.type-box').val()){
            url = url?url+'&type='+$('.type-box').val():'?type='+$('.type-box').val();
        }

        if($('.status-box').val()){
            url = url?url+'&status='+$('.status-box').val():'?status='+$('.status-box').val();
        }

        if($('.user-box').val()){
            url = url?url+'&type='+$('.user-box').val():'?user='+$('.user-box').val();
        }

        if($('.date-ini-box').val()){
            url = url?url+'&dateIni='+$('.date-ini-box').val():'?dateIni='+$('.date-ini-box').val();
        }

        if($('.date-end-box').val()){
            url = url?url+'&dateEnd='+$('.date-end-box').val():'?dateEnd='+$('.date-end-box').val();
        }

        if($('.cpt-box').val()){
            url = url?url+'&procedure='+$('.cpt-box').val():'?procedure='+$('.cpt-box').val();
        }

        window.location.href = config.routes.url_search_list+url;
    }

    if($("#itemSpeciality").length){
        new SlimSelect({
            select: '#itemSpeciality'
        })
    }

    var slimSingle = null;

    if($(".select-single").length){
        slimSingle = new SlimSelect({
            select: '.select-single'
        })
    }


    if($("#itemBirthday").length){
        var dtToday = new Date();
    
        var month = dtToday.getMonth() + 1;// jan=0; feb=1 .......
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if(month < 10)
            month = '0' + month.toString();
        if(day < 10)
            day = '0' + day.toString();
    	//var minDate = year + '-' + month + '-' + day;
        var maxDate = year + '-' + month + '-' + day;
    	$('#itemBirthday').attr('max', maxDate);
    }

    if($(".select-multi").length){

        $( ".select-multi" ).each(function( index ) {            
            new SlimSelect({
                select: "#"+$(this).attr("id")+"",
                placeholder: 'Seleccione un elemento de la lista.',
            })
        });
    }

    $('#itemBillingType').on('change', function () {
        let type = $(this).val();

        if(type == 'natural'){
            $('.society-show').hide();
        }else{
            $('.society-show').show();
        }
    });

    $("input[name=itemBankType]").change(function () {
        let type = $(this).val();

        //console.log('hola',type);

        if(type == 'internacional'){
            $('.internacional-show').removeClass('d-hide');            
        }else{
            $('.internacional-show').addClass('d-hide');  
        }
    });

    $('#itemFee').on('change', function () {
        let type = $(this).val();

        if(type == 'no'){
            $('.fee-show').hide();
        }else{
            $('.fee-show').show();
        }
    });

    if($("#itemCountry").length){        

        if($('#itemCountry').val()){
            getProvince($('#itemCountry').val());
        }
    }

    $('#itemCountry').on('change', function () {
        getProvince($(this).val());
    });

    function getProvince(country) {
        //let country = $(this).val();
        
        $.ajax({
            url: config.routes.url_get_province,
            type:"POST",
            data:{
                country:country
            },
            success:function(response){
                          
                if(response) {
                    //$(".loader-wrapper").fadeOut();
                  
                    $('#itemProvince').empty();
                    
                    $.each(response.provinces, function(index, item) {   

                        if(config.vars.province == item.id){
                            $('#itemProvince')
                            .append($("<option></option>")
                            .attr("value",item.id)
                            .attr("selected",true)
                            .text(item.name)); 
                        }else{
                            $('#itemProvince')
                            .append($("<option></option>")
                            .attr("value",item.id)
                            .text(item.name));  
                        }							

                    });
                    
                    $("#itemProvince").prop('disabled', false);

                }else{
                  ///error
                }
            },
        });
    }

    // file input
    $("#main").on("change", ".custom-file-input", function(){
    //$(".custom-file-input").on("change", function() {
        var fileName = $(this).val().split("\\").pop();
        $(this).siblings(".custom-file-label").addClass("selected").html(fileName);
    });

    $('#itemDoctorCons').on('change', function () {
        $('#InfoDoctorCons').show();
        $('#labelDoctorCons').html( $( "#itemDoctorCons option:selected" ).text() );
    });

    $('#itemDateIni').on('change', function () { 
        setMinMaxDate();     
    });

    function setMinMaxDate(){
        $("#itemDateEnd").attr("min", $("#itemDateIni").val());
    }
    /*
    var fileIdRef = config.vars.fileCount;
    
    if(fileIdRef > 0){
        
        for (var i=1; i <= fileIdRef; i++) {
            if($('#fileRef').val()){
                $('#fileRef').val($('#fileRef').val()+','+i);
            }else{
                $('#fileRef').val(i);
            }            
        }
    }
    */
    

    $(".add-file").click(function(){
        addFiles($(this).attr("data-ref"));       
    });

    $("#file-wrapper").on("click", ".remove-file", function(){
        removeFileDom( $(this).attr("data-id") );        
    });

    function removeFileDom(refId){
        $( "#file-"+refId ).remove();

        /*
        var fileCount = $('#fileRef').val().split(",");
        $('#fileRef').val('');        
        var idsLength = fileIds.length;

        for (var i=0; i < idsLength; i++) {
            
            if(fileIds[i] != refId){
                
                if($('#fileRef').val()){
                    $('#fileRef').val($('#fileRef').val()+','+fileIds[i]);
                }else{
                    $('#fileRef').val(fileIds[i]);
                }
            }
        }
        */
        
    }

    $("#file-wrapper").on("click", ".remove-file-exist", function(){

        $("#bg-loading").fadeIn();

        $(this).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

        let id = $(this).attr("data-id");
        let idDoc = $(this).attr("data-did");
        let idRef = $(this).attr("data-ref-id");

        $.ajax({
            url: config.routes.url_delete_docs,
            type:"POST",
            data:{
                idDoc:idDoc,
                idRef:idRef
            },
            success:function(response){
                          
                if(response) {
                    removeFileDom( id ); 
                    $("#bg-loading").fadeOut();
                }else{
                  ///error
                  $("#bg-loading").fadeOut(); 
                }
            },
        });

               
    });

    function addFiles(wrapper, id=false){

        let fileIdRef =  $('#fileRef').val();
        fileIdRef++;

        if(id){
            fileIdRef = id;
        }

        let form = '<div class="row" id="file-'+fileIdRef+'"><div class="col-10"><!-- ini row --><div class="row mt-3"><div class="col-md-6 col-lg-4 mb-4"><label for="itemFile'+fileIdRef+'" class=" col-form-label tc-gray4 regular fs-12">'+config.lang.select_file+'</label><div class="custom-file form-control @error("itemFile'+fileIdRef+'") is-invalid @enderror"><input type="file" class="custom-file-input" id="itemFile'+fileIdRef+'" name="itemFile'+fileIdRef+'"><label class="custom-file-label tc-gray2 regular fs-14" for="customFile">'+config.lang.select_file+'</label></div></div><div class="col-md-6 col-lg-8 mb-3"><label for="itemType'+fileIdRef+'" class="col-12 col-form-label tc-gray4 regular fs-12 ">'+config.lang.file_content+'</label><select class="form-control @error("itemType'+fileIdRef+'") is-invalid @enderror select-multi" id="itemType'+fileIdRef+'" name="itemType'+fileIdRef+'[]" multiple></select></div></div><!-- end row --></div><div class="col-2 d-flex justify-content-center align-self-md-center"><div><button type="button" class="btn btn-custom transparent line-1 mt-file1 remove-file" data-ref="file-wrapper" data-id="'+fileIdRef+'"><img class="" width="16" src="'+config.img.icon_trash_dark+'" alt="Mefaback" ></button></div></div></div>';
        $( "#"+wrapper ).append( $(form) );

        //console.log(config.vars.docTypes);
        var obj = JSON.parse(config.vars.docTypes);
        //console.log(obj);
        
        $.each(obj, function(index, item) {  
            $('#itemType'+fileIdRef)
            .append($("<option></option>")
            .attr("value",item.id)
            .text(item.name)); 
        });

        /*
        if($('#fileRef').val()){
            $('#fileRef').val($('#fileRef').val()+','+fileIdRef);
        }else{
            $('#fileRef').val(fileIdRef);
        }
        */

       $('#fileRef').val(fileIdRef);

        new SlimSelect({
            select: '#itemType'+fileIdRef
        })
    }

    /*
    if(config.vars.fileRef){

        //$('#fileRef').val('');
        var fileIds = config.vars.fileRef.split(",");
        var idsLength = fileIds.length;

        for (var i=fileIdRef; i < idsLength; i++) {
            addFiles('file-wrapper', fileIds[i]);
        }
    }
    */

    $('#itemPatientCons').on('change', function () {
        
        $('#labelPatientCons').html( $( "#itemPatientCons option:selected" ).text() );
        
        let element = $(this).find('option:selected'); 
        let insurance = element.attr("data-insurance"); 

        $('#intemInsurance').html(insurance);

        $('#InfoPatientCons').show();

    });    

    $('.copy-doctor').on('change', function () {
        
        let element = $(this).find('option:selected'); 
        let dataCount = element.attr("data-count"); 
        
        $('#doctorId'+dataCount).val($(this).val());

    });    

    $('#itemDoctorProc').on('change', function () {    
        
        let element = $(this).find('option:selected'); 
        let doctorId = element.val();
        let insuranceId = $('#itemInsuranceType').val();
        //let insuranceId = 1;

        //console.log('hola',insuranceId);
        if(doctorId != 'default'){

            if(insuranceId){

                $("#bg-loading").fadeIn();
                let doctorCount = $('#doctorCount').val();
                doctorCount++;

                $.ajax({
                    url: config.routes.url_get_doctor_form,
                    type:"POST",
                    data:{
                        insuranceId: insuranceId,
                        doctorId: doctorId,
                        count: doctorCount
                    },
                    success:function(response){
                                
                        if(response) {
                            $("#bg-loading").fadeOut();        
                        
                            //$( "#add-doctor-wrapper" ).html('');
                            $( "#add-doctor-wrapper" ).append( response.form );
                            //$("#itemDoctorProc").val('');
                            slimSingle.set('default');
                            //console.log('reset');
                            
                            /*
                            var doctorsIds = $('#doctorRef').val().split(",");
                            doctorsIds.push(doctorCount); 
                            var idsLength = doctorsIds.length;

                            $('#doctorRef').val('');                    

                            for (var i=0; i < idsLength; i++) {                        
                                    
                                if($('#doctorRef').val()){
                                    $('#doctorRef').val($('#doctorRef').val()+','+doctorsIds[i]);
                                }else{
                                    $('#doctorRef').val(doctorsIds[i]);
                                }
                                
                            }
                            */

                           $('#doctorCount').val(doctorCount); 

                            //slimSingle.set(['default']);  
                            addMedical(false, doctorCount);                      

                        }else{
                            ///error
                            $("#bg-loading").fadeOut();
                        }
                    },
                });
            }else{
                $('.modal-title').html(config.lang.select_insurance_title);
                $('.modal-body').html(config.lang.select_insurance_body);
                $("#alert-box").modal();
            }
        }

    });

    $("#add-doctor-wrapper").on("keyup", ".calculate-25", function(e){        
        $('#itemCoPay_'+$(this).attr('data-ref')+'_'+$(this).attr('data-medical')).val( parseFloat( $(this).val() * .25 ).toFixed(2) );        
    });

    $("#add-doctor-wrapper").on("click", ".remove-doctor", function(){
        removeDoctor( $(this).attr("data-id") ); 
    });

    $("#add-doctor-wrapper").on("click", ".remove-doctor-exist", function(){

        $("#bg-loading").fadeIn();

        $(this).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

        let id = $(this).attr("data-id");
        let idDoc = $(this).attr("data-did");
        let idRef = $(this).attr("data-ref-id");

        $.ajax({
            url: config.routes.url_delete_procedure_doctor,
            type:"POST",
            data:{
                idDoc:idDoc,
                idRef:idRef
            },
            success:function(response){
                          
                if(response) {
                    removeDoctor( id ); 
                    $("#bg-loading").fadeOut();
                }else{
                  ///error
                  $("#bg-loading").fadeOut(); 
                }
            },
        });
               
    });

    function removeDoctor(refId){
        $( "#doctor-wrapper"+refId ).remove();
        /*
        var fileIds = $('#doctorRef').val().split(",");
        $('#doctorRef').val('');        
        var idsLength = fileIds.length;

        for (var i=0; i < idsLength; i++) {
            
            if(fileIds[i] != refId){
                
                if($('#doctorRef').val()){
                    $('#doctorRef').val($('#doctorRef').val()+','+fileIds[i]);
                }else{
                    $('#doctorRef').val(fileIds[i]);
                }
            }
        }
        */
    }
    
    //var medicalCount = config.vars.medicalCount;   

    
    if($("#itemPatientProc").length){ 
        if($('#itemPatientProc').val() != "default"){
            getInsurance($('#itemPatientProc').val());
        }
    }

    $('#itemPatientProc').on('change', function () {
        if($(this).val() != "default"){
            getInsurance($(this).val());
        }
    });
    
    $('#itemPatientProcEdit').on('change', function () {
        if($(this).val() != "default"){
            getInsurance($(this).val());
        }
    });

    function getInsurance(patientId) {
        //let country = $(this).val();

        if(patientId && patientId != 'default'){

            //console.log(config.vars.procedureCopy+' hola')

            if(!config.vars.procedureCopy){
                $('#add-doctor-wrapper').html('');
            }
            
            $.ajax({
                url: config.routes.url_get_insurance,
                type:"POST",
                data:{
                    patientId: patientId
                },
                success:function(response){
                            
                    if(response) {
                        //$(".loader-wrapper").fadeOut();
                    
                        $('#itemInsuranceType').empty();
                        
                        $.each(response.insurances, function(index, item) {   

                            if(config.vars.insurance == item.id){
                                $('#itemInsuranceType')
                                .append($("<option></option>")
                                .attr("value",item.id)
                                .attr("selected",true)
                                .text(item.name)); 
                            }else{
                                $('#itemInsuranceType')
                                .append($("<option></option>")
                                .attr("value",item.id)
                                .text(item.name));  
                            }							

                        });
                        
                        //$("#itemProvince").prop('disabled', false);

                    }else{
                    ///error
                    }
                },
            });
        }//else{
        //    $('.modal-title').html(config.lang.select_patient_title);
        //    $('.modal-body').html(config.lang.select_patient_body);
        //    $("#alert-box").modal();
        //}
    }

    $("#add-doctor-wrapper").on("click", ".add-medical", function(){
        addMedical( false, $(this).attr('data-ref') );
    });

    function addMedical(id=false, ref){
        //medicalCount++;

        //if(id){
        //    medicalCount = id;
        //}

        let medicalCount = $('#itemCount'+ref).val();

        if(medicalCount){
            medicalCount++;
        }else{
            medicalCount = 1;
        }
        
        let form = '<hr><div class="row" id="medical-'+ref+'-'+medicalCount+'">';
        form += '<div class="col-10">';
        form += '<div class="row"><!-- ini row -->';
        
        form += '<div class="col-md-4 mb-4"><!-- ini col --><label for="iteMedicalProcedure_'+ref+'_'+medicalCount+'" class="col-12 col-form-label tc-gray3 regular fs-12">'+config.lang.procedure_type+' <span class="tc-red">*</span></label><select class="form-control select-multi" required id="iteMedicalProcedure_'+ref+'_'+medicalCount+'" name="iteMedicalProcedure_'+ref+'_'+medicalCount+'"></select></div><!-- end col -->';
        form += '<div class="col-md-4 mb-4"><!-- ini col --><label for="itemDate_'+ref+'_'+medicalCount+'" class="col-12 col-form-label tc-gray4 regular fs-12">'+config.lang.procedure_date+' <span class="tc-red">*</span></label><input type="date" value="'+$("#itemDateIni").val()+'" class="form-control tc-gray2 regular fs-14" required id="itemDate_'+ref+'_'+medicalCount+'" name="itemDate_'+ref+'_'+medicalCount+'" value=""></div><!-- end col -->';
        
        form += '<div class="col-md-4 mb-4">';
        form += '<label for="itemFee_'+ref+'_'+medicalCount+'" class="col-12 col-form-label tc-gray4 regular fs-12">'+config.lang.fees+' <span class="tc-red">*</span></label>';
        form += '<input type="tel" class="form-control tc-gray2 regular fs-14 calculate-25" required data-ref="'+ref+'" data-medical="'+medicalCount+'" id="itemFee_'+ref+'_'+medicalCount+'" name="itemFee_'+ref+'_'+medicalCount+'" value="">';
        form += '</div>';

        if( $('#itemInsuranceType').val() == config.vars.tricareId){

            //form += '<div class="col-md-4 mb-4">';
            //form += '<label for="itemFeeFf_'+ref+'_'+medicalCount+'" class="col-12 col-form-label tc-gray4 regular fs-12">'+config.lang.fees_ff+' <span class="tc-red">*</span></label>';
            //form += '<input type="tel" class="form-control tc-gray2 regular fs-14" requiredid="itemFeeFf_'+ref+'_'+medicalCount+'" name="itemFeeFf_'+ref+'_'+medicalCount+'" value="">';
            //form += '</div>';

            form += '<div class="col-md-4 mb-4">';
            form += '<label for="itemCoPay_'+ref+'_'+medicalCount+'" class="col-12 col-form-label tc-gray4 regular fs-12">'+config.lang.co_pay+' <span class="tc-red">*</span></label>';
            form += '<input type="tel" class="form-control tc-gray2 regular fs-14" required id="itemCoPay_'+ref+'_'+medicalCount+'" name="itemCoPay_'+ref+'_'+medicalCount+'" value="">';
            form += '</div>';

        }

        form += '</div><!-- end row -->';
        form += '</div><!-- end col -->';
        
        form += '<div class="col-2 d-flex justify-content-center"><div><button type="button" class="btn btn-custom transparent line-1 mt-file1 remove-medical" data-id="'+medicalCount+'" data-ref="'+ref+'"><img class="" width="16" src="'+config.img.icon_trash_dark+'" alt="Mefaback" ></button></div></div>';

        form += '</div><!-- end row -->';

        $( "#add-medical-wrapper"+ref ).append( $(form) );
        //console.log(config.vars.medicalTypes)
        var obj = JSON.parse(config.vars.medicalTypes);

        $('#iteMedicalProcedure_'+ref+'_'+medicalCount)
        .append($("<option></option>")
        .attr("value", '')
        .text('Seleccione'));
        
        $.each(obj, function(index, item) {  
            $('#iteMedicalProcedure_'+ref+'_'+medicalCount)
            .append($("<option></option>")
            .attr("value",item.id)
            .text("CPT "+item.code_cpt+": "+item.name)); 
        });

        //if($('#medicalRef').val()){
        //$('#medicalRef').val($('#medicalRef').val()+','+medicalCount);
        //}else{
        //    $('#medicalRef').val(medicalCount);
        //}

        $('#itemCount'+ref).val(medicalCount);

        new SlimSelect({
            select: '#iteMedicalProcedure_'+ref+'_'+medicalCount
        })
    }

    /*
    if(config.vars.medicalRef){

        $('#medicalRef').val('');
        var fileIds = config.vars.medicalRef.split(",");
        var idsLength = fileIds.length;

        for (var i=0; i < idsLength; i++) {
            addMedical(fileIds[i]);
        }
    }
    */

    $("#add-doctor-wrapper").on("click", ".remove-medical", function(){
        //removeMedical( $(this).attr("data-id") );        
        $( "#medical-"+$(this).attr("data-ref")+"-"+$(this).attr("data-id") ).remove();
    });

    
    function removeMedical(refId){
        $( "#medical-"+refId ).remove();

        var fileIds = $('#medicalRef').val().split(",");
        $('#medicalRef').val('');        
        var idsLength = fileIds.length;

        for (var i=0; i < idsLength; i++) {
            
            if(fileIds[i] != refId){
                
                if($('#medicalRef').val()){
                    $('#medicalRef').val($('#medicalRef').val()+','+fileIds[i]);
                }else{
                    $('#medicalRef').val(fileIds[i]);
                }
            }
        }
    }

    $("#add-doctor-wrapper").on("click", ".remove-medical-exist", function(){

        $("#bg-loading").fadeIn();

        $(this).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

        let id = $(this).attr("data-id");
        let idDoc = $(this).attr("data-did");
        let idRef = $(this).attr("data-ref-id");

        $.ajax({
            url: config.routes.url_delete_procedure_medical,
            type:"POST",
            data:{
                idDoc:idDoc,
                idRef:idRef
            },
            success:function(response){
                          
                if(response) {
                    removeMedical( id ); 
                    $("#bg-loading").fadeOut();
                }else{
                  ///error
                  $("#bg-loading").fadeOut(); 
                }
            },
        });

               
    });

    $('#itemStatus').on('change', function () {  

        $("#bg-loading").fadeIn();

        let status = $(this).val();
        let idDoc = $(this).attr("data-id");

        $.ajax({
            url: config.routes.url_status_update,
            type:"POST",
            data:{
                idDoc:idDoc,
                status:status
            },
            success:function(response){
                          
                if(response) {
                    //removeFileDom( id ); 
                    $("#bg-loading").fadeOut();
                }else{
                  ///error
                  $("#bg-loading").fadeOut(); 
                }
            },
        });

               
    });

    $("#invoice-form").submit(function(e) {

        e.preventDefault(); // avoid to execute the actual submit of the form.
    
        var form = $(this);
        var url = form.attr('action');
        
        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), 
            success: function(data){
                $("#invoice-link").attr("href", data.url)
                $('#submit-form').hide();
                $('#cancel-form').hide();

                $('#back-form').show();
                $('#invoice-link').show();
                window.open(data.url);
                
            }
        });
    
        
    });

    $('.no-payed').click(function(){
        if($(this).prop("checked") == true){
            $('#'+$(this).attr("data-ref")).val('');
        }
        else if($(this).prop("checked") == false){
            $('#'+$(this).attr("data-ref")).val($(this).attr("data-amount"));
        }
    });

    // reports
    /*
    $('#doctors-table').DataTable({
        processing: true,
        serverSide: true,
        ajax: config.routes.url_report_doctor_payments,
        columns: [
            { data: 'id', name: 'id' },
            { data: 'name', name: 'name' },
            { data: 'email', name: 'email' },
            { data: 'created_at', name: 'created_at' },
        ],
        order: [[0, 'desc']]
    });
    */

    
    $('#doctors-payment').dataTable( {
        paging: true,
        searching: true,
        ordering:  true,
        pageLength: 20,
        order: [[ 0, "desc" ]],
        //dom: 'Btirp',
        dom: "<'row'<'col-6'<'toolbar'><'buttons'B>><'col-6'f>>" +
			"<'row'<'col-12'tr>>" +
			"<'row'<'col-5'i><'col-7'p>>",
        buttons: [
            'excel','print'
        ]
    });
    

    /*
    var tablePayment = $('#doctors-payment').DataTable({
        dom: 'Btirp',
        buttons: [
        {
          extend: 'excelHtml5',
          text: 'Excel',
          exportOptions: {
            format: {
                body: function ( data, row, column, node ) {
            
                    if(column == 1 || column == 2){
                        //need to change double quotes to single
                        data = data.replace( /"/g, "'" );
                        // replace p with br
                        data = data.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br>');                   
                        // replace div with br
                        data = data.replace(/<div[^>]*>/g, '').replace(/<\/div>/g, '<br>');                   
                        data = remove_tags(data);                   
                        //split at each new line
                        splitData = data.split('<br>');                   
                        //remove empty string
                        splitData = splitData.filter(function(v){return v!==''});
                            
                        data = '';
                        for (i=0; i < splitData.length; i++) {
                            //add escaped double quotes around each line
                            data += '\"' + splitData[i].trim() + '\"';
                            //if its not the last line add CHAR(13)
                            if (i + 1 < splitData.length) {
                                data += ', CHAR(10), ';
                            }
                        }
                            
                        //Add concat function
                        data = 'CONCATENATE(' + data + ')';
                    }

                    return data;
    
                }
            }    
        },
        customize: function( xlsx ) {
        
            var sSh = xlsx.xl['styles.xml'];
            var styleSheet = sSh.childNodes[0];
        
            cellXfs = styleSheet.childNodes[5];
                
            // Using this instead of "" (required for Excel 2007+, not for 2003)
            var ns = "http://schemas.openxmlformats.org/spreadsheetml/2006/main";
        
            // Create a custom style
            var lastStyleNum = $('cellXfs xf', sSh).length - 1;
            var wrappedTopIndex = lastStyleNum + 1;
            var newStyle = document.createElementNS(ns, "xf");
            // Customize style
            newStyle.setAttribute("numFmtId", 0);
            newStyle.setAttribute("fontId", 0);
            newStyle.setAttribute("fillId", 0);
            newStyle.setAttribute("borderId", 0);
            newStyle.setAttribute("applyFont", 1);
            newStyle.setAttribute("applyFill", 1);
            newStyle.setAttribute("applyBorder", 1);
            newStyle.setAttribute("xfId", 0);
            // Alignment (optional)
            var align = document.createElementNS(ns, "alignment");
            align.setAttribute("vertical", "top");
            align.setAttribute("wrapText", 1);
            newStyle.appendChild(align);
            // Append the style next to the other ones
            cellXfs.appendChild(newStyle);
                
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
            var firstExcelRow = 3;
                
            tablePending.rows({order: 'applied', search: 'applied'}).every( function ( rowIdx, tableLoop, rowLoop ) {
        
                var node = this.node();
                var num_colonne = $(node).find("td").length;
                    
                // the cell with biggest number of line inside it determine the height of entire row
                var maxCountLinesRow = 1;
            
                for ( var indexCol = 1; indexCol <= num_colonne; indexCol++ ) {


                    if(indexCol == 3 || indexCol == 4){
                    
                        var letterExcel = columnToLetter(indexCol);        
                        
                        $('c[r=' + letterExcel + ( firstExcelRow + rowLoop ) + ']', sheet).each(function(e) {        
                            // how many lines are present in this cell?
                            var countLines = ($('is t', this).text().match(/\"/g) || []).length / 2;
                        
                            if ( countLines > maxCountLinesRow ) {
                                maxCountLinesRow = countLines;
                            }
                    
                            if ($('is t', this).text()) {
                                //wrap text top vertical top
                                $(this).attr('s', wrappedTopIndex );
                                
                                //change the type to `str` which is a formula
                                $(this).attr('t', 'str');
                                //append the concat formula
                                $(this).append('<f>' + $('is t', this).text() + '</f>');
                                //remove the inlineStr
                                $('is', this).remove();
                            }
                        
                        });
                        
                        $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('ht', maxCountLinesRow * 26);
                        $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('customHeight', 1);
                    }
                }
        
            });          
          
        
        }
    
        }]
    
    });
    */

    /*
    $('#claim-pending').dataTable( {
        paging: true,
        searching: true,
        ordering:  true,
        pageLength: 20,
        //order: [[ 0, "desc" ]],
        //dom: 'Bfrtip',
        dom: "<'row'<'col-6'<'toolbar'><'buttons'B>><'col-6'f>>" +
			"<'row'<'col-12'tr>>" +
			"<'row'<'col-5'i><'col-7'p>>",
        buttons: [
            'excel'
        ]
    });
    */

    var tablePending = $('#claim-pending').DataTable({
        //dom: 'Btirp',
        searching: true,
        dom: "<'row'<'col-6'<'toolbar'><'buttons'B>><'col-6'f>>" +
			"<'row'<'col-12'tr>>" +
			"<'row'<'col-5'i><'col-7'p>>",
        buttons: [
        {
          extend: 'excelHtml5',
          text: 'Excel',
          exportOptions: {
            format: {
                body: function ( data, row, column, node ) {
                    //console.log('column '+column);
                    if(column == 5 || column == 6){
                        //need to change double quotes to single
                        data = data.replace( /"/g, "'" );
                        // replace p with br
                        data = data.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br>');                   
                        // replace div with br
                        data = data.replace(/<div[^>]*>/g, '').replace(/<\/div>/g, '<br>');                   
                        data = remove_tags(data);                   
                        //split at each new line
                        splitData = data.split('<br>');                   
                        //remove empty string
                        splitData = splitData.filter(function(v){return v!==''});
                            
                        data = '';
                        for (i=0; i < splitData.length; i++) {
                            //add escaped double quotes around each line
                            data += '\"' + splitData[i].trim() + '\"';
                            //if its not the last line add CHAR(13)
                            if (i + 1 < splitData.length) {
                                data += ', CHAR(10), ';
                            }
                        }
                            
                        //Add concat function
                        data = 'CONCATENATE(' + data + ')';
                    }

                    if (column == 0) {
                        data = data.replace( /<.*?>/ig, "");
                        data = data.trim();
                    }

                    return data;
    
                }
            }    
            },
            customize: function( xlsx ) {
            
                var sSh = xlsx.xl['styles.xml'];
                var styleSheet = sSh.childNodes[0];
            
                cellXfs = styleSheet.childNodes[5];
                    
                // Using this instead of "" (required for Excel 2007+, not for 2003)
                var ns = "http://schemas.openxmlformats.org/spreadsheetml/2006/main";
            
                // Create a custom style
                var lastStyleNum = $('cellXfs xf', sSh).length - 1;
                var wrappedTopIndex = lastStyleNum + 1;
                var newStyle = document.createElementNS(ns, "xf");
                // Customize style
                newStyle.setAttribute("numFmtId", 0);
                newStyle.setAttribute("fontId", 0);
                newStyle.setAttribute("fillId", 0);
                newStyle.setAttribute("borderId", 0);
                newStyle.setAttribute("applyFont", 1);
                newStyle.setAttribute("applyFill", 1);
                newStyle.setAttribute("applyBorder", 1);
                newStyle.setAttribute("xfId", 0);
                // Alignment (optional)
                var align = document.createElementNS(ns, "alignment");
                align.setAttribute("vertical", "top");
                align.setAttribute("wrapText", 1);
                newStyle.appendChild(align);
                // Append the style next to the other ones
                cellXfs.appendChild(newStyle);
                    
                var sheet = xlsx.xl.worksheets['sheet1.xml'];
                var firstExcelRow = 3;
                    
                tablePending.rows({order: 'applied', search: 'applied'}).every( function ( rowIdx, tableLoop, rowLoop ) {
            
                    var node = this.node();
                    var num_colonne = $(node).find("td").length;
                        
                    // the cell with biggest number of line inside it determine the height of entire row
                    var maxCountLinesRow = 1;
                
                    for ( var indexCol = 1; indexCol <= num_colonne; indexCol++ ) {


                        if(indexCol == 6 || indexCol == 7){
                        
                            var letterExcel = columnToLetter(indexCol);        
                            
                            $('c[r=' + letterExcel + ( firstExcelRow + rowLoop ) + ']', sheet).each(function(e) {        
                                // how many lines are present in this cell?
                                var countLines = ($('is t', this).text().match(/\"/g) || []).length / 2;
                            
                                if ( countLines > maxCountLinesRow ) {
                                    maxCountLinesRow = countLines;
                                }
                        
                                if ($('is t', this).text()) {
                                    //wrap text top vertical top
                                    $(this).attr('s', wrappedTopIndex );
                                    
                                    //change the type to `str` which is a formula
                                    $(this).attr('t', 'str');
                                    //append the concat formula
                                    $(this).append('<f>' + $('is t', this).text() + '</f>');
                                    //remove the inlineStr
                                    $('is', this).remove();
                                }
                            
                            });
                            
                            $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('ht', maxCountLinesRow * 26);
                            $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('customHeight', 1);
                        }
                    }
            
                });            
            
            
            }
    
        },'print']
    
    });

    var tablePending = $('#claim-history').DataTable({
        //dom: 'Btirp',
        searching: true,
        "order": [[ 2, "desc" ]],
        dom: "<'row'<'col-6'<'toolbar'><'buttons'B>><'col-6'f>>" +
			"<'row'<'col-12'tr>>" +
			"<'row'<'col-5'i><'col-7'p>>",
        buttons: [
        {
          extend: 'excelHtml5',
          text: 'Excel',
          exportOptions: {
            format: {
                body: function ( data, row, column, node ) {
                    //console.log('column '+column);
                    if(column == 5 || column == 6){
                        //need to change double quotes to single
                        data = data.replace( /"/g, "'" );
                        // replace p with br
                        data = data.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br>');                   
                        // replace div with br
                        data = data.replace(/<div[^>]*>/g, '').replace(/<\/div>/g, '<br>');                   
                        data = remove_tags(data);                   
                        //split at each new line
                        splitData = data.split('<br>');                   
                        //remove empty string
                        splitData = splitData.filter(function(v){return v!==''});
                            
                        data = '';
                        for (i=0; i < splitData.length; i++) {
                            //add escaped double quotes around each line
                            data += '\"' + splitData[i].trim() + '\"';
                            //if its not the last line add CHAR(13)
                            if (i + 1 < splitData.length) {
                                data += ', CHAR(10), ';
                            }
                        }
                            
                        //Add concat function
                        data = 'CONCATENATE(' + data + ')';
                    }

                    if (column == 0) {
                        data = data.replace( /<.*?>/ig, "");
                        data = data.trim();
                    }

                    return data;
    
                }
            }    
            },
            customize: function( xlsx ) {
            
                var sSh = xlsx.xl['styles.xml'];
                var styleSheet = sSh.childNodes[0];
            
                cellXfs = styleSheet.childNodes[5];
                    
                // Using this instead of "" (required for Excel 2007+, not for 2003)
                var ns = "http://schemas.openxmlformats.org/spreadsheetml/2006/main";
            
                // Create a custom style
                var lastStyleNum = $('cellXfs xf', sSh).length - 1;
                var wrappedTopIndex = lastStyleNum + 1;
                var newStyle = document.createElementNS(ns, "xf");
                // Customize style
                newStyle.setAttribute("numFmtId", 0);
                newStyle.setAttribute("fontId", 0);
                newStyle.setAttribute("fillId", 0);
                newStyle.setAttribute("borderId", 0);
                newStyle.setAttribute("applyFont", 1);
                newStyle.setAttribute("applyFill", 1);
                newStyle.setAttribute("applyBorder", 1);
                newStyle.setAttribute("xfId", 0);
                // Alignment (optional)
                var align = document.createElementNS(ns, "alignment");
                align.setAttribute("vertical", "top");
                align.setAttribute("wrapText", 1);
                newStyle.appendChild(align);
                // Append the style next to the other ones
                cellXfs.appendChild(newStyle);
                    
                var sheet = xlsx.xl.worksheets['sheet1.xml'];
                var firstExcelRow = 3;
                    
                tablePending.rows({order: 'applied', search: 'applied'}).every( function ( rowIdx, tableLoop, rowLoop ) {
            
                    var node = this.node();
                    var num_colonne = $(node).find("td").length;
                        
                    // the cell with biggest number of line inside it determine the height of entire row
                    var maxCountLinesRow = 1;
                
                    for ( var indexCol = 1; indexCol <= num_colonne; indexCol++ ) {


                        if(indexCol == 6 || indexCol == 7){
                        
                            var letterExcel = columnToLetter(indexCol);        
                            
                            $('c[r=' + letterExcel + ( firstExcelRow + rowLoop ) + ']', sheet).each(function(e) {        
                                // how many lines are present in this cell?
                                var countLines = ($('is t', this).text().match(/\"/g) || []).length / 2;
                            
                                if ( countLines > maxCountLinesRow ) {
                                    maxCountLinesRow = countLines;
                                }
                        
                                if ($('is t', this).text()) {
                                    //wrap text top vertical top
                                    $(this).attr('s', wrappedTopIndex );
                                    
                                    //change the type to `str` which is a formula
                                    $(this).attr('t', 'str');
                                    //append the concat formula
                                    $(this).append('<f>' + $('is t', this).text() + '</f>');
                                    //remove the inlineStr
                                    $('is', this).remove();
                                }
                            
                            });
                            
                            $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('ht', maxCountLinesRow * 26);
                            $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('customHeight', 1);
                        }
                    }
            
                });            
            
            
            }
    
        },'print']
    
    });
    
    /*
    $('#client-history').dataTable( {
        paging: true,
        searching: true,
        ordering:  true,
        pageLength: 20,
        order: [[ 2, "desc" ]],
        //dom: 'Bfrtip',
        dom: "<'row'<'col-6'<'toolbar'><'buttons'B>><'col-6'f>>" +
			"<'row'<'col-12'tr>>" +
			"<'row'<'col-5'i><'col-7'p>>",
        buttons: [
            'excel'
        ]
    });
    */

    var tableClient = $('#client-history').DataTable({
        //dom: 'Btirp',
        searching: true,
        dom: "<'row'<'col-6'<'toolbar'><'buttons'B>><'col-6'f>>" +
			"<'row'<'col-12'tr>>" +
			"<'row'<'col-5'i><'col-7'p>>",
        buttons: [
        {
          extend: 'excelHtml5',
          text: 'Excel',
          exportOptions: {
            format: {
                body: function ( data, row, column, node ) {
                    //console.log('column '+column);
                    if(column == 5 || column == 6){
                        //need to change double quotes to single
                        data = data.replace( /"/g, "'" );
                        // replace p with br
                        data = data.replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '<br>');                   
                        // replace div with br
                        data = data.replace(/<div[^>]*>/g, '').replace(/<\/div>/g, '<br>');                   
                        data = remove_tags(data);                   
                        //split at each new line
                        splitData = data.split('<br>');                   
                        //remove empty string
                        splitData = splitData.filter(function(v){return v!==''});
                            
                        data = '';
                        for (i=0; i < splitData.length; i++) {
                            //add escaped double quotes around each line
                            data += '\"' + splitData[i].trim() + '\"';
                            //if its not the last line add CHAR(13)
                            if (i + 1 < splitData.length) {
                                data += ', CHAR(10), ';
                            }
                        }
                            
                        //Add concat function
                        data = 'CONCATENATE(' + data + ')';
                    }

                    if (column == 1) {
                        console.log(data);
                        data = data.replace( /<.*?>/ig, "");
                        data = data.trim();
                        console.log(data);
                    }

                    return data;
    
                }
            }    
            },
            customize: function( xlsx ) {
            
                var sSh = xlsx.xl['styles.xml'];
                var styleSheet = sSh.childNodes[0];
            
                cellXfs = styleSheet.childNodes[5];
                    
                // Using this instead of "" (required for Excel 2007+, not for 2003)
                var ns = "http://schemas.openxmlformats.org/spreadsheetml/2006/main";
            
                // Create a custom style
                var lastStyleNum = $('cellXfs xf', sSh).length - 1;
                var wrappedTopIndex = lastStyleNum + 1;
                var newStyle = document.createElementNS(ns, "xf");
                // Customize style
                newStyle.setAttribute("numFmtId", 0);
                newStyle.setAttribute("fontId", 0);
                newStyle.setAttribute("fillId", 0);
                newStyle.setAttribute("borderId", 0);
                newStyle.setAttribute("applyFont", 1);
                newStyle.setAttribute("applyFill", 1);
                newStyle.setAttribute("applyBorder", 1);
                newStyle.setAttribute("xfId", 0);
                // Alignment (optional)
                var align = document.createElementNS(ns, "alignment");
                align.setAttribute("vertical", "top");
                align.setAttribute("wrapText", 1);
                newStyle.appendChild(align);
                // Append the style next to the other ones
                cellXfs.appendChild(newStyle);
                    
                var sheet = xlsx.xl.worksheets['sheet1.xml'];
                var firstExcelRow = 3;
                    
                tableClient.rows({order: 'applied', search: 'applied'}).every( function ( rowIdx, tableLoop, rowLoop ) {
            
                    var node = this.node();
                    var num_colonne = $(node).find("td").length;
                        
                    // the cell with biggest number of line inside it determine the height of entire row
                    var maxCountLinesRow = 1;
                
                    for ( var indexCol = 1; indexCol <= num_colonne; indexCol++ ) {


                        if(indexCol == 6 || indexCol == 7){
                        
                            var letterExcel = columnToLetter(indexCol);        
                            
                            $('c[r=' + letterExcel + ( firstExcelRow + rowLoop ) + ']', sheet).each(function(e) {        
                                // how many lines are present in this cell?
                                var countLines = ($('is t', this).text().match(/\"/g) || []).length / 2;
                            
                                if ( countLines > maxCountLinesRow ) {
                                    maxCountLinesRow = countLines;
                                }
                        
                                if ($('is t', this).text()) {
                                    //wrap text top vertical top
                                    $(this).attr('s', wrappedTopIndex );
                                    
                                    //change the type to `str` which is a formula
                                    $(this).attr('t', 'str');
                                    //append the concat formula
                                    $(this).append('<f>' + $('is t', this).text() + '</f>');
                                    //remove the inlineStr
                                    $('is', this).remove();
                                }
                            
                            });
                            
                            $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('ht', maxCountLinesRow * 26);
                            $('row:nth-child('+( firstExcelRow + rowLoop )+')', sheet).attr('customHeight', 1);
                        }
                    }
            
                });            
            
            
            }
    
        },'print']
    
    });

    $("div.toolbar").html('<p class="mb-0 tc-gray4">Exportar</p>');

    $(".add-appeal").click(function(){
        $(this).hide();
        $('#wrapper-appeal'+$(this).attr("data-ref")).show();        
    });

    // send emails
    $('#sendEmail').click(function (e) {

        e.preventDefault();

        if($('#emails').val()){

            $(this).prop('disabled', true);
            let btn = $(this);

            $.ajax({
                data: $('#emailForm').serialize(),
                url: config.routes.url_send_email_proc,
                type: "POST",
                dataType: 'json',
                success: function (data) {
                    $('#emailForm').trigger("reset");
                    //$('#ajaxModel').modal('hide');  
                    btn.prop('disabled', false);    
                    $('#email-success').show();
                },
                statusCode: {
                   404: function() {
                      //alert('Procedimiento no encontrado');
                      $('#email-error').html(data.responseJSON.message);
                      $('#email-error').show();
                   }
                },
                error: function (data) {
                    //alert('Algo no salio bien');
                    //console.log('Error:', data.responseJSON.message);
                    //$('#saveBtn').html('Save Changes');
                    $('#email-error').html(data.responseJSON.message);
                    $('#email-error').show();
                    btn.prop('disabled', false);
                }

            });
        }

    });

});// end ready

$(window).bind("pageshow", function(event) {
    if (event.originalEvent.persisted) {

        $('form').find(':submit').css('background', function(){
            $(this).html($(this).attr('data-text'));
            $(this).prop('disabled', false); 
            
        });
    }
}); // end show


function columnToLetter(column){
    var temp, letter = '';
    while (column > 0){
        temp = (column - 1) % 26;
        letter = String.fromCharCode(temp + 65) + letter;
        column = (column - temp - 1) / 26;
    }
    return letter;
}

function remove_tags(html){
	html = html.replace(/<br>/g,"$br$"); 
	html = html.replace(/(?:\r\n|\r|\n)/g, '$n$');
	var tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	html = tmp.textContent||tmp.innerText;

	html = html.replace(/\$br\$/g,"<br>");  
	html = html.replace(/\$n\$/g,"<br>");
	
	return html;
}